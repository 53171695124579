<template>
  <div>
    <!-- Banner Section -->
    <section class="banner-section">
      <div class="main-slider-carousel owl-carousel owl-theme">

        <div class="slide">
          <div class="bg-layer-one" :style="{backgroundImage: 'url('+slider0+')'}"></div>
          <!--                    <div class="bg-layer-two" :style="{backgroundImage: 'url('+slider2+')'}"></div>-->
          <div class="icon-layer-one" :style="{backgroundImage: 'url('+sliderIcon1+')'}"></div>
          <div class="icon-layer-two" :style="{backgroundImage: 'url('+sliderIcon2+')'}"></div>
          <div class="icon-layer-three" :style="{backgroundImage: 'url('+sliderPattern1+')'}"></div>
          <div class="icon-layer-four" :style="{backgroundImage: 'url('+sliderPattern2+')'}"></div>
          <div class="auto-container">
            <div class="row clearfix">

              <!-- Content Column -->
              <div class="content-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column">
                  <h1>Embrace the <br> New World</h1>
                  <div class="text text-dark" style="text-shadow: 1px 1px 1px #000000">
                    Explore the World of Cryptocurrency <br> Investing with Expert Guidance and Insights
                  </div>
                  <div class="btns-box">
                    <a href="/register" class="theme-btn btn-style-one">
                      <span class="txt">Get Started</span>
                    </a>
                  </div>
                </div>
              </div>

              <!-- Image Column -->
              <!--                            <div class="image-column col-lg-6 col-md-12 col-sm-12">-->
              <!--                                <div class="inner-column">-->
              <!--                                    <div class="image">-->
              <!--                                        <img src="../../assets/images/main-slider/image-1.png" alt="" />-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->

            </div>
          </div>
        </div>

        <div class="slide">
          <div class="bg-layer-one" :style="{backgroundImage: 'url('+slider1+')'}"></div>
          <!--                    <div class="bg-layer-two" :style="{backgroundImage: 'url('+slider2+')'}"></div>-->
          <div class="icon-layer-one" :style="{backgroundImage: 'url('+sliderIcon1+')'}"></div>
          <div class="icon-layer-two" :style="{backgroundImage: 'url('+sliderIcon2+')'}"></div>
          <div class="icon-layer-three" :style="{backgroundImage: 'url('+sliderPattern1+')'}"></div>
          <div class="icon-layer-four" :style="{backgroundImage: 'url('+sliderPattern2+')'}"></div>
          <div class="auto-container">
            <div class="row clearfix">

              <!-- Content Column -->
              <div class="content-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column">
                  <h1>Take a Bold Step <br> To Change Your <br> World</h1>
                  <div class="text text-dark" style="text-shadow: 1px 1px 1px #000000">
                    You have dreams. We have a culturally diverse, <br> forward thinking team ready to help you achieve your dreams
                  </div>
                  <div class="btns-box">
                    <a href="/register" class="theme-btn btn-style-one">
                      <span class="txt">Get Started</span>
                    </a>
                  </div>
                </div>
              </div>

              <!-- Image Column -->
              <!--                            <div class="image-column col-lg-6 col-md-12 col-sm-12">-->
              <!--                                <div class="inner-column">-->
              <!--                                    <div class="image">-->
              <!--                                        <img src="../../assets/images/main-slider/image-1.png" alt="" />-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->

            </div>
          </div>
        </div>

        <div class="slide">
          <div class="bg-layer-one" :style="{backgroundImage: 'url('+slider11+')'}"></div>
          <!--                    <div class="bg-layer-two" :style="{backgroundImage: 'url('+slider2+')'}"></div>-->
          <div class="icon-layer-one" :style="{backgroundImage: 'url('+sliderIcon1+')'}"></div>
          <div class="icon-layer-two" :style="{backgroundImage: 'url('+sliderIcon2+')'}"></div>
          <div class="icon-layer-three" :style="{backgroundImage: 'url('+sliderPattern1+')'}"></div>
          <div class="icon-layer-four" :style="{backgroundImage: 'url('+sliderPattern2+')'}"></div>
          <div class="auto-container">
            <div class="row clearfix">

              <!-- Content Column -->
              <div class="content-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column">
                  <h1>We Are The Leaders In <br> Bitcoin Trade</h1>
                  <div class="text" style="text-shadow: 1px 1px 1px #000000">
                    We help our investors make the best out of <br> cryptocurrency innovation through our team of expert traders
                  </div>
                  <div class="btns-box">
                    <a href="/about" class="theme-btn btn-style-one"><span class="txt">learn more</span></a>
                  </div>
                </div>
              </div>

              <!-- Image Column -->
              <!--                            <div class="image-column col-lg-6 col-md-12 col-sm-12">-->
              <!--                                <div class="inner-column">-->
              <!--                                    <div class="image">-->
              <!--                                        <img src="../../assets/images/main-slider/image-1.png" alt="" />-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->

            </div>
          </div>
        </div>

        <div class="slide">
          <div class="bg-layer-one" :style="{backgroundImage: 'url('+slider12+')'}"></div>
          <!--                    <div class="bg-layer-two" :style="{backgroundImage: 'url('+slider2+')'}"></div>-->
          <div class="icon-layer-one" :style="{backgroundImage: 'url('+sliderIcon1+')'}"></div>
          <div class="icon-layer-two" :style="{backgroundImage: 'url('+sliderIcon2+')'}"></div>
          <div class="icon-layer-three" :style="{backgroundImage: 'url('+sliderPattern1+')'}"></div>
          <div class="icon-layer-four" :style="{backgroundImage: 'url('+sliderPattern2+')'}"></div>
          <div class="auto-container">
            <div class="row clearfix">

              <!-- Content Column -->
              <div class="content-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column">
                  <h1>We Are Experts<br> In Real Estate</h1>
                  <div class="text" style="text-shadow: 1px 1px 1px #000000">
                    Our vast experience in real estate over the years makes us one of the best if not the best real estate agents to invest with.
                  </div>
                  <div class="btns-box">
                    <router-link :to="{name: 'RealEstate'}" class="theme-btn btn-style-one">
                      <span class="txt">Review options</span>
                    </router-link>
                  </div>
                </div>
              </div>

              <!-- Image Column -->
              <!--                            <div class="image-column col-lg-6 col-md-12 col-sm-12">-->
              <!--                                <div class="inner-column">-->
              <!--                                    <div class="image">-->
              <!--                                        <img src="../../assets/images/main-slider/image-1.png" alt="" />-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->

            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- End Banner Section -->

    <!-- Services Section -->
    <section class="services-section">
      <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern1+')'}"></div>
      <div class="auto-container">
        <!-- Sec Title -->
        <div class="sec-title centered">
          <div class="circle"></div>
          <div class="title">TrustEarns</div>
          <h2>We are the best <br> in finance</h2>
        </div>
        <div class="row clearfix">

          <!-- Service Block -->
          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="icon-box">
                <img src="../../assets/images/resource/service-1.png" alt="" />
              </div>
              <div class="lower-content">
                <h4><a href="javascript:void(0)">The Non-farm Payrolls (NFP)</a></h4>
                <div class="text text-justify">
                  The Non-farm payrolls report is one of the most anticipated economic news reports in the
                  forex markets. It is published the first Friday of the month at 8:30AM Eastern time by the U.S. Bureau of labor statistics.

                  The data release actually includes a number of statistics, and not just the NFP(which is the change in the number of employees
                  in the country, not including farm, government, private and non- profit employees.). Another metric included in the data
                  release is the unemployment rate.

                  As one of the most anticipated economic news events of the months, Integrated Binary Options Services focus mainly on currency pairs(especially those
                  involving the U.S. Dollar) and typically see big price movements in the minutes and hours after the data is released.
                  This makes it a great opportunity for our traders with a sound strategy to take advantage of the volatility.
                </div>
              </div>
            </div>
          </div>

          <!-- Service Block -->
          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="icon-box">
                <img src="../../assets/images/resource/service-2.png" alt="" />
              </div>
              <div class="lower-content">
                <h4><a href="javascript:void(0)">Get a Loan</a></h4>
                <div class="text text-justify">
                  Getting a loan doesn’t have to be intimidating, with the right lender it can be
                  a simple process. You only need a lender committed to taking the mystery out of the mortgage loan
                  process! At Trust Earns, DGS we understand! Our investors want simple facts, honest answers
                  and competitive products.

                  DGS automatically offers loan services to investors with over $50,000 investment either in our normal TrustEarns financial

                  Services packages or the NFP plans. Investors over $50,000 are entitled to loans of $200,000-1millon dollars yearly with 5%
                  paid monthly, or the investor could wish to compound the interest till the time limit, provided all required information and
                  identity of the investor are duly confirmed by the TrustEarns loan board.

                  Every investor above $50,000 is provided with a personal account manager and the investor has a direct communication with
                  the manager in order to see that our loan offers are secured.

                  GREAT INVESTING WITH THE TrustEarns FAMILY
                </div>
              </div>
            </div>
          </div>

          <!-- Service Block -->
          <div class="service-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="icon-box">
                <img src="../../assets/images/resource/service-3.png" alt="" />
              </div>
              <div class="lower-content">
                <h4><a href="javascript:void(0)">Trade an Asset</a></h4>
                <div class="text text-justify">
                  We are everything a traditional financial institution is not. We set out to give
                  investors better, simpler and more profitable ways to become financially successful and secure.
                  <br>
                  We offer various assets ranging from cryptocurrencies, to forex, commodities, indexes, stocks, ETFs, and the all new Pre-IPOs.
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End Services Section -->

    <!-- About Section -->
    <section class="about-section">
      <div class="auto-container">
        <div class="row clearfix">

          <!-- Image Column -->
          <div class="image-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon1+')'}"></div>
              <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon2+')'}"></div>
              <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon3+')'}"></div>
              <div class="icon-layer-four" :style="{backgroundImage: 'url('+icon4+')'}"></div>
              <div class="image">
                <img src="../../assets/images/resource/about.png" alt="" />
              </div>
              <!-- Video Box Two -->
              <div class="video-box">
                <figure class="video-image">
                  <img src="../../assets/images/resource/video-image.jpg" alt="">
                </figure>
                <a href="https://www.youtube.com/watch?v=YnYC6Yl0OgY&ab_channel=AllGuides" class="lightbox-image overlay-box"><span class="flaticon-play-arrow"><i class="ripple"></i></span></a>
              </div>
            </div>
          </div>

          <!-- Content Column -->
          <div class="content-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <!-- Sec Title -->
              <div class="sec-title">
                <div class="title">ABOUT THE AGENCY</div>
                <h2>We have been known to give investors the better choice.</h2>
              </div>
              <div class="text">
                With over 153,000 investments under our management, $5 billion+ in assets under our
                administration, over 11 industry awards, we have made Trust Earns the safe haven for investors who
                want to trust their financial partner to be capable of helping them reach their financial
                goals, and in due time, without the fear of disappointments.
              </div>
              <div class="author-box">
                <div class="box-inner">
                  <div class="author-image">
                    <img src="../../assets/images/resource/author-1.jpg" alt="" />
                  </div>
                  Company Director
                  <strong>Elena Hachaturianc</strong>
                  <span class="signature">
									<img src="../../assets/images/icons/signature.png" alt="" />
								</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End About Section -->

    <!-- Counter Section -->
    <section class="counter-section">
      <div class="auto-container">
        <div class="inner-container">
          <!-- Fact Counter -->
          <div class="fact-counter">
            <div class="row clearfix">

              <!-- Column -->
              <div class="column counter-column col-lg-4 col-md-6 col-sm-12">
                <div class="inner">
                  <div class="icon"><i class="fa fa-user"></i></div>
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="2500" data-stop="49649">0</span>+
                    </div>
                    <h4 class="counter-title">Investors</h4>
                  </div>
                </div>
              </div>

              <!-- Column -->
              <div class="column counter-column col-lg-4 col-md-6 col-sm-12">
                <div class="inner">
                  <div class="icon"><i class="fa fa-university"></i></div>
                  <div class="content">
                    <div class="count-outer count-box alternate">
                      <span class="count-text" data-speed="2000" data-stop="5">0</span>M+
                    </div>
                    <h4 class="counter-title">Transactions</h4>
                  </div>
                </div>
              </div>

              <!-- Column -->
              <div class="column counter-column col-lg-4 col-md-6 col-sm-12">
                <div class="inner">
                  <div class="icon"><i class="fa fa-users"></i></div>
                  <div class="content">
                    <div class="count-outer count-box">
                      <span class="count-text" data-speed="2000" data-stop="32">0</span>+
                    </div>
                    <h4 class="counter-title">Partners</h4>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End Counter Section -->

    <!-- CTA Section -->
    <section class="cta-section">
      <div class="auto-container">
        <div class="image-one">
          <img src="../../assets/images/resource/cta-1.jpg" alt="" />
        </div>
        <div class="image-two">
          <img src="../../assets/images/resource/cta-2.jpg" alt="" />
        </div>
        <div class="image-three">
          <img src="../../assets/images/resource/cta-3.jpg" alt="" />
        </div>
        <div class="icon">
          <img src="../../assets/images/resource/cta.png" alt="" />
        </div>
        <div class="title">At TrustEarns</div>
        <h2>You can trust us to help make <br> your financial dream come true</h2>
        <div class="btns-box">
          <a href="/register" class="theme-btn btn-style-one"><span class="txt">Register Today</span></a>
        </div>
      </div>
    </section>
    <!-- End CTA Section -->

    <!-- Team Section -->
    <section class="team-section">
      <div class="auto-container">
        <!-- Sec Title -->
        <div class="sec-title centered">
          <div class="circle"></div>
          <div class="title">meet our clients</div>
          <h2>Hear what they have <br> to say.</h2>
        </div>
        <div class="row clearfix">

          <!-- Team Block -->
          <div class="team-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="image-outer">
                <div class="pattern-layer" :style="{backgroundImage: 'url('+teamPattern+')'}"></div>
                <div class="image">
                  <img src="../../assets/images/resource/team-1.jpg" alt="" />
                </div>
              </div>
              <div class="lower-content">
                <div class="title">INVESTOR</div>
                <h3><a href="javascript:void(0)">Cynthia Carl</a></h3>
                <p>

                  At first, I doubted it but to my surprise TrustEarn started paying me at the due date
                </p>
                <!-- Social Box -->
                <!--                                <ul class="social-box">-->
                <!--                                    <li class="facebook"><a href="javascript:void(0)" class="fab fa-facebook-f"></a></li>-->
                <!--                                    <li class="twitter"><a href="javascript:void(0)" class="fab fa-linkedin-in"></a></li>-->
                <!--                                    <li class="google"><a href="javascript:void(0)" class="fab fa-google"></a></li>-->
                <!--                                    <li class="linkedin"><a href="javascript:void(0)" class="fab fa-linkedin-in"></a></li>-->
                <!--                                </ul>-->
              </div>
            </div>
          </div>

          <!-- Team Block -->
          <div class="team-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="image-outer">
                <div class="pattern-layer" :style="{backgroundImage: 'url('+teamPattern+')'}"></div>
                <div class="image">
                  <img src="../../assets/images/resource/team-2.jpg" alt="" />
                </div>
              </div>
              <div class="lower-content">
                <div class="title">INVESTOR</div>
                <h3><a href="javascript:void(0)">Michael</a></h3>
                <p>
                  Thank you everyone at TrustEarn at first I thought is not for real, but I have been receiving my payout every week, is a good trading platform...
                </p>
              </div>
            </div>
          </div>

          <!-- Team Block -->
          <div class="team-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="image-outer">
                <div class="pattern-layer" :style="{backgroundImage: 'url('+teamPattern+')'}"></div>
                <div class="image">
                  <img src="../../assets/images/resource/team-3.jpg" alt="" />
                </div>
              </div>
              <div class="lower-content">
                <div class="title">INVESTOR</div>
                <h3><a href="javascript:void(0)">Nora Woodward</a></h3>
                <p>
                  I keep recommending to my friends and family because of their overwhelmingly impressive financial services
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End Team Section -->

    <!-- Steps Section -->
    <section class="steps-section">
      <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
      <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern2+')'}"></div>
      <div class="auto-container">
        <!-- Sec Title -->
        <div class="sec-title">
          <div class="title">3 Simple Steps</div>
          <h2>Simple Investment <br> Process</h2>
        </div>
        <div class="row clearfix">

          <!-- Steps Column -->
          <div class="steps-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="text">
                You will not be alone. We have designed our system in a way that makes it easy for everyone
                to understand and trade
              </div>
              <div class="row clearfix">

                <!-- Step Block -->
                <div class="step-block col-lg-6 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="number">1</div>
                    <div class="icon"><img src="../../assets/images/svg/account.svg" style="width: 100px;height:100px" alt="" /></div>
                    <h5>Create an Account</h5>
                  </div>
                </div>

                <!-- Step Block -->
                <div class="step-block col-lg-6 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="number">2</div>
                    <div class="icon"><img src="../../assets/images/svg/deposit.svg" style="width: 100px;height:100px" alt="" /></div>
                    <h5>Make Deposit</h5>
                  </div>
                </div>

                <!-- Step Block -->
                <div class="step-block offset-lg-3 col-lg-6 col-md-6 col-sm-12">
                  <div class="inner-box">
                    <div class="number">3</div>
                    <div class="icon"><img src="../../assets/images/svg/withdrawal.svg" style="width: 100px;height:100px" alt="" /></div>
                    <h5>Make Withdrawal</h5>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Images Column -->
          <div class="images-column col-lg-6 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon6+')'}"></div>
              <div class="image">
                <img src="../../assets/images/resource/step-1.jpg" alt="" />
              </div>
              <div class="image-two">
                <img src="../../assets/images/resource/step-2.jpg" alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End Steps Section -->

    <!-- Clients Section -->
    <!--        <section class="clients-section">-->
    <!--            <div class="auto-container">-->
    <!--                &lt;!&ndash; Title Box &ndash;&gt;-->
    <!--                <div class="title-box">-->
    <!--                    <div class="box-inner">-->
    <!--                        <h4>You'll be in good company</h4>-->
    <!--                    </div>-->
    <!--                </div>-->

    <!--                <div class="sponsors-outer">-->
    <!--                    &lt;!&ndash;Sponsors Carousel&ndash;&gt;-->
    <!--                    <ul class="sponsors-carousel owl-carousel owl-theme">-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/1.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/2.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/3.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/4.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/5.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/1.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/2.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/3.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/4.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/5.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/1.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/2.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/3.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/4.png" alt=""></a></figure></li>-->
    <!--                        <li class="slide-item"><figure class="image-box"><a href="javascript:void(0)"><img src="../assets/images/clients/5.png" alt=""></a></figure></li>-->
    <!--                    </ul>-->
    <!--                </div>-->

    <!--            </div>-->
    <!--        </section>-->
    <!-- End Clients Section -->

    <!-- Testimonial Section -->
    <!--        <section class="testimonial-section">-->
    <!--            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern1+')'}"></div>-->
    <!--            <div class="auto-container">-->
    <!--                <div class="testimonial-carousel owl-carousel owl-theme">-->

    <!--                    &lt;!&ndash; Testimonial Block &ndash;&gt;-->
    <!--                    <div class="testimonial-block">-->
    <!--                        <div class="inner-box">-->
    <!--                            <div class="pattern-icon" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>-->
    <!--                            <div class="text">The design and build took much less time than expected and proved to be exceptional value for money.</div>-->
    <!--                            <div class="author-info">-->
    <!--                                Mathous Bolluen-->
    <!--                                <span>Mathous Bolluen</span>-->
    <!--                            </div>-->
    <!--                            <div class="author-image">-->
    <!--                                <img src="../assets/images/resource/author-2.jpg" alt="" />-->
    <!--                            </div>-->
    <!--                            <div class="quote-icon">-->
    <!--                                <img src="../assets/images/icons/quote-icon-1.png" alt="" />-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->

    <!--                    &lt;!&ndash; Testimonial Block &ndash;&gt;-->
    <!--                    <div class="testimonial-block">-->
    <!--                        <div class="inner-box">-->
    <!--                            <div class="pattern-icon" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>-->
    <!--                            <div class="text">The design and build took much less time than expected and proved to be exceptional value for money.</div>-->
    <!--                            <div class="author-info">-->
    <!--                                WIllimes James-->
    <!--                                <span>Mathous Bolluen</span>-->
    <!--                            </div>-->
    <!--                            <div class="author-image">-->
    <!--                                <img src="../assets/images/resource/author-3.jpg" alt="" />-->
    <!--                            </div>-->
    <!--                            <div class="quote-icon">-->
    <!--                                <img src="../assets/images/icons/quote-icon-1.png" alt="" />-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->

    <!--                    &lt;!&ndash; Testimonial Block &ndash;&gt;-->
    <!--                    <div class="testimonial-block">-->
    <!--                        <div class="inner-box">-->
    <!--                            <div class="pattern-icon" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>-->
    <!--                            <div class="text">The design and build took much less time than expected and proved to be exceptional value for money.</div>-->
    <!--                            <div class="author-info">-->
    <!--                                Mathous Bolluen-->
    <!--                                <span>Mathous Bolluen</span>-->
    <!--                            </div>-->
    <!--                            <div class="author-image">-->
    <!--                                <img src="../assets/images/resource/author-2.jpg" alt="" />-->
    <!--                            </div>-->
    <!--                            <div class="quote-icon">-->
    <!--                                <img src="../assets/images/icons/quote-icon-1.png" alt="" />-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->

    <!--                    &lt;!&ndash; Testimonial Block &ndash;&gt;-->
    <!--                    <div class="testimonial-block">-->
    <!--                        <div class="inner-box">-->
    <!--                            <div class="pattern-icon" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>-->
    <!--                            <div class="text">The design and build took much less time than expected and proved to be exceptional value for money.</div>-->
    <!--                            <div class="author-info">-->
    <!--                                WIllimes James-->
    <!--                                <span>Mathous Bolluen</span>-->
    <!--                            </div>-->
    <!--                            <div class="author-image">-->
    <!--                                <img src="../assets/images/resource/author-3.jpg" alt="" />-->
    <!--                            </div>-->
    <!--                            <div class="quote-icon">-->
    <!--                                <img src="../assets/images/icons/quote-icon-1.png" alt="" />-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->

    <!--                </div>-->
    <!--            </div>-->
    <!--        </section>-->
    <!-- End Testimonial Section -->

    <!-- Newslettter Section -->
    <contact-small/>
    <!-- End Newslettter Section -->
  </div>
</template>

<script>
import slider0 from "../../assets/images/main-slider/bg-0-1.jpg"
import slider1 from "../../assets/images/main-slider/bg-1-1.jpg"
import slider11 from "../../assets/images/main-slider/bg-1-2.jpg"
import slider12 from "../../assets/images/main-slider/bg-1-3.jpg"
import slider2 from "../../assets/images/main-slider/bg-2.png"
import sliderIcon1 from "../../assets/images/main-slider/icon-1.png"
import sliderIcon2 from "../../assets/images/main-slider/icon-1.png"
import sliderPattern1 from "../../assets/images/main-slider/pattern-1.png"
import sliderPattern2 from "../../assets/images/main-slider/icon-2.png"

import bgPattern1 from "../../assets/images/background/pattern-1.png"
import bgPattern2 from "../../assets/images/background/pattern-2.png"
import bgPattern3 from "../../assets/images/background/pattern-3.png"


import icon1 from "../../assets/images/icons/icon-1.png"
import icon2 from "../../assets/images/icons/icon-2.png"
import icon3 from "../../assets/images/icons/icon-3.png"
import icon4 from "../../assets/images/icons/icon-4.png"
import icon5 from "../../assets/images/icons/icon-5.png"
import icon6 from "../../assets/images/icons/icon-6.png"

import iconPattern1 from "../../assets/images/icons/pattern-1.png"


import teamPattern from "../../assets/images/icons/team-pattern.png"

import contactSmall from "../../components/home/contactSmall";

export default {
  name: 'Home',
  data(){
    return {
      slider0,
      slider1,slider11,slider12, slider2,
      sliderIcon1, sliderIcon2,
      sliderPattern1, sliderPattern2,
      bgPattern1, icon1, icon2, icon3, icon4,
      teamPattern, bgPattern2,
      icon5, icon6, bgPattern3, iconPattern1
    }
  },
  components: {
    contactSmall
  },
  mounted() {
    if ($('.main-slider-carousel').length) {
      $('.main-slider-carousel').owlCarousel({
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        loop:true,
        margin:0,
        nav:true,
        autoHeight: true,
        smartSpeed: 500,
        autoplay: 6000,
        navText: [ '<span class="flaticon-back"></span>', '<span class="flaticon-next-1"></span>' ],
        responsive:{
          0:{
            items:1
          },
          600:{
            items:1
          },
          800:{
            items:1
          },
          1024:{
            items:1
          },
          1200:{
            items:1
          }
        }
      });
    }
    if($('.count-box').length){
      $('.count-box').appear(function(){

        var $t = $(this),
            n = $t.find(".count-text").attr("data-stop"),
            r = parseInt($t.find(".count-text").attr("data-speed"), 10);

        if (!$t.hasClass("counted")) {
          $t.addClass("counted");
          $({
            countNum: $t.find(".count-text").text()
          }).animate({
            countNum: n
          }, {
            duration: r,
            easing: "linear",
            step: function() {
              $t.find(".count-text").text(Math.floor(this.countNum));
            },
            complete: function() {
              $t.find(".count-text").text(this.countNum);
            }
          });
        }

      },{accY: 0});
    }
  }
}
</script>

<style scoped>
.slide{
  position: relative;
}
.slide:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.3);
  z-index: 1
}
</style>