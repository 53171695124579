<template>
    <section class="newslettter-section">
        <div class="auto-container">
            <div class="inner-container">
                <div class="row clearfix">
                    <!-- Title Column -->
                    <div class="title-column col-lg-6 col-md-12 col-sm-12">
                        <div class="inner-column">
                            <div class="icon flaticon-send-1"></div>
                            <h3>We are more than obliged to <br> assist you</h3>
                        </div>
                    </div>
                    <!-- Form Column -->
                    <div class="form-column col-lg-6 col-md-12 col-sm-12">
                        <div class="inner-column">
                            <div class="newsletter-form">
                                <form method="post" @submit.prevent="submitContact" ref="contactForm">
                                    <div class="form-group">
                                        <input type="text" placeholder="Full Name" v-model="contact.name">
                                    </div>
                                    <div class="form-group mt-2">
                                        <input type="email" name="email" v-model="contact.email" placeholder="Enter your email Address" required="">
                                    </div>
                                    <div class="form-group mt-2">
                                        <input type="text" placeholder="Subject" v-model="contact.subject" required="">
                                    </div>
                                    <div class="form-group mt-2">
                                        <textarea class="form-control" style="resize: none" v-model="contact.message" cols="30" rows="5" placeholder="Message"/>
                                    </div>
                                    <div class="form-group mt-2">
                                        <button type="submit" class="btn btn-outline-light" style="position: relative">
                                            <span class="txt">Send</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Newsletter Boxed -->
            </div>
        </div>
    </section>
</template>

<script>
import Contact from "../../models/contact";

export default {
    name: "contactSmall",
    data(){
        return {
            contact: new Contact().data
        }
    },
    methods: {
        async submitContact(){
            if(!this.contact.name || !this.contact.email || !this.contact.name || !this.contact.subject || !this.contact.message){
                return toastr.warning("Complete all fields")
            }
            const loader = this.$loading.show({container: this.$refs.contactForm});
            const response = await this.$store.dispatch('contact/addContact', this.contact);
            loader.hide();
            if(response.status){
                this.contact = new Contact().data;
                alert("Message Received, Note that our representative will contact you within the next 24 hours")
            }else{
                alert(response.message);
            }
        }
    }
}
</script>

<style scoped>

</style>